.product_item2_row {
 display: flex;
 flex-direction: row;
 align-items: center;   
}
.product-item-2-container {
  display: flex;
  cursor: pointer;
  width: 100%;
  flex-direction: row;
  padding-left: 10px;
  
  .product-item-2-img-container {
    width: 100px;
    height: 95px;
    border-radius: 4px;
    border: 1px solid #ACACAC;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 15px;
    position: relative;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .product-item-2-promotion-icon-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      cursor: pointer;
      user-select: none;
      img {
        max-width: 100%;
        max-height: 100%;
        rotate: -30deg;
      }
    }
  }

  .product-item-2-placeholder {
    height: 30px;
  }
  
  .product-item-2-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-item-2-des {
      color: #393939;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 162.5% */
      white-space: pre;
    }

    .product-item-2-price {
      color: #ACACAC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      max-width: 100%;
    }
    
    .product-item-2-bottom-container {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 10px;

      .product-item-2-bottom-input {
        width: 75px;
        height: 25px;
        border: 1px solid #C8C8C8;
        display: flex;
        align-items: center;
        border-radius: 0;

        .ant-input-number-input-wrap {
          height: 100%;

          .ant-input-number-input {
            height: 100%;
          }
        }
      }
    }

    .product-item-2-btn {
      width: 134px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: black;

      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
    .product-item-2-btn-disabled {
      width: 134px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: #AEAEAE;

      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
  }
  
  .product-item-2-right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .product-item-2-right-favorite-icon-container {
      width: 37px;
      height: 33px;
      cursor: pointer;
      user-select: none;
      
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    
    .product-item-2-right-total-price {
      color: #393939;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
    }
    
    .product-item-2-right-qty {
      color: #ACACAC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      max-width: 100%;
      text-align: right;
    }
  }
}
