.register-account-container {
  width: 100%;
  background: rgba(255, 255, 255, 0.92);
  display: flex;
  flex-direction: column;
  align-items: center;

  .register-account-title {
    color: black;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 54px;
  }

  .register-account-tip-container {
    color: #393939;
    font-feature-settings: 'salt' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
    padding: 32px 0;
    margin-bottom: 40px;

    .register-account-tip-link {
      color: black;
      font-weight: 700;
      text-decoration-line: underline;
      cursor: pointer;
      user-select: none;
    }
  }

  .register-account-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: black;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
