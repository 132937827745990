.terms-of-service-mobile-container {
  width: 100%;
  padding: 50px 60px;

  .terms-of-service-content-container {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */

    display: flex;
    flex-direction: column;
    gap: 20px;

    .terms-of-service-content-list-item-container {
      display: flex;

      span {
        margin-right: 5px;
      }
    }

    .terms-of-service-content-list-2-container {
      padding-left: 26px;

      > div {
        display: flex;

        &:before {
          content: '·';
          font-weight: 900;
          width: 12px;
        }
      }
    }
  }
}
