.header-mobile-container {
  width: 100%;
  padding: 0 15px ;
  border-bottom: 1px solid rgba(102, 102, 102, 0.35);
  position: relative;

  .header-left-detail-page-container {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;

    .header-left-detail-page-icon {
      color: #393939;
      font-size: 18px;
    }

    .header-left-detail-page-title {
      color: #393939;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 13px; /* 130% */
      width: 280px;
    }
  }

  .header-left-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    
    .header-left-container {
      height: 60px;
      cursor: pointer;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .header-right-menu-icon {
      position: absolute;
      right: 18px;
      top: 18px;
      font-size: 22px;
    }
  }
  .header-right-container {
    overflow: hidden;
    transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    .header-right-menu-wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 16px 20px;
      flex-direction: column;
      padding-bottom: 20px;
      .header-right-zipcode-container {
        height: 23px;
        display: flex;
        align-items: center;
        gap: 10px;
        //padding: 0 12px;
        //border-radius: 6px;
        //border: 1px solid rgba(102, 102, 102, 0.35);

        .header-right-zipcode-title {
          color: #062101;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }

        .header-right-zipcode-val {
          color: #393939;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .header-right-menu-item-container {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0;
        text-align: left;
        cursor: pointer;
        user-select: none;
        text-transform: uppercase;
      }

      .header-right-menu-item-active-container {
        font-weight: 700;
      }
    }
  }
}
