.home-mobile-container {
  width: 100%;

  .home-header-container {
    //padding: 0 5px;

    .content-header-title {
      padding: 0 5px;
    }
  }
  
  .home-group-mobile-container {
    padding: 55px 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .home-group {
      text-align: center;
      .home-group-image {
        height: 60px;
      }
      .home-group-name {
        line-height: 50px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  
  .home-cat2-mobile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 10px;
        
    .home-cat2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .home-cat2-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  
  .home-group-container {
    padding: 55px 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .home-group {
      text-align: center;
      cursor: pointer;
      .home-group-image {
        height: 60px;
      }
      .home-group-name {
        line-height: 50px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .home-product-group-container {
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .home-product-group-title {
      color: #a57b4f;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }

  .home-img-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 16px;
    gap: 20px 0;

    .home-img-item-container {
      width: 48%;
      //height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }

  .home-whats-news-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
