.order-success-mobile-container {
  width: 100vw !important;

  .ant-modal-content {
    padding: 20px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
  }
}
