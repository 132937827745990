.my-order-detail-mobile-container {
  width: 100%;

  .my-order-detail-top-container {
    display: flex;
    align-items: center;
    gap: 30px;

    color: #393939;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    margin-bottom: 50px;

    cursor: pointer;
    user-select: none;
  }

  .my-order-detail-content-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .my-order-detail-shopping-container {
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #C4C4C4;

      .my-order-detail-shopping-item-container {
        padding: 10px 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        min-height: 52px;
        justify-content: space-between;

        .my-order-detail-shopping-item-label {
          min-width: 150px;
        }

        .my-order-detail-shopping-item-value {
          //flex: 1;
        }
      }
    }

    .my-order-detail-payment-info-container {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #A1A1A1;

      .my-order-detail-payment-info-title {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;

        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .my-order-detail-payment-info-line {
        width: 100%;
        display: flex;

        &:after {
          content: ' ';
          width: 100%;
          height: 1px;
          background: #ACACAC;
        }
      }

      .my-order-detail-payment-info-item-container {
        padding: 10px 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        height: 52px;

        .my-order-detail-payment-info-item-label {
          width: 250px;
        }

        .my-order-detail-payment-info-item-value-container {
          display: flex;
          align-items: center;
          gap: 8px;

          .my-order-detail-payment-info-item-value-icon-container {
            width: 32px;
            height: 32px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }

    .my-order-detail-order-summary-container {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #A1A1A1;

      .my-order-detail-order-summary-title {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;

        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .my-order-detail-order-summary-line {
        width: 100%;
        display: flex;

        &:after {
          content: ' ';
          width: 100%;
          height: 1px;
          background: #ACACAC;
        }
      }

      .my-order-detail-order-summary-product-list-container {
        width: 100%;
        padding: 36px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .my-order-detail-order-summary-product-list-item-container {
          height: 140px;
          display: flex;
          gap: 20px;

          .my-order-detail-order-summary-product-list-item-left-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .my-order-detail-order-summary-product-list-item-left-img-container {
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .my-order-detail-order-summary-product-list-item-left-qty-container {
              display: flex;
              align-items: center;
              gap: 10px;

              .my-order-detail-order-summary-product-list-item-left-qty-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 162.5% */
              }

              .my-order-detail-order-summary-product-list-item-left-qty-input {
                width: 75px;
                height: 25px;
                border: 1px solid #C8C8C8;
                display: flex;
                align-items: center;
                border-radius: 0;

                .ant-input-number-input-wrap {
                  height: 100%;

                  .ant-input-number-input {
                    height: 100%;
                  }
                }
              }
            }
          }

          .my-order-detail-order-summary-product-list-item-right-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .my-order-detail-order-summary-product-list-item-right-info-container {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .my-order-detail-order-summary-product-list-item-right-info-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .my-order-detail-order-summary-product-list-item-right-info-price {
                color: #393939;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; /* 110% */

                span {
                  font-size: 14px;
                  font-weight: 400;
                }
              }

              .my-order-detail-order-summary-product-list-item-right-info-size {
                color: #393939;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .my-order-detail-order-summary-product-list-item-right-btn {
              color: #a57b4f;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 185.714% */
              text-decoration-line: underline;
              cursor: pointer;
              user-select: none;
              width: max-content;
            }
          }
        }
      }

      .my-order-detail-order-summary-order-info-container {
        width: 100%;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .my-order-detail-order-summary-order-info-line {
          padding: 20px 0 8px;
        }

        .my-order-detail-order-summary-order-info-item-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .my-order-detail-order-summary-order-info-item-title {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }

          .my-order-detail-order-summary-order-info-item-val {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }

    .my-order-detail-invoice-summary-container {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #A1A1A1;

      .my-order-detail-invoice-summary-title {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;

        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .my-order-detail-invoice-summary-line {
        width: 100%;
        display: flex;

        &:after {
          content: ' ';
          width: 100%;
          height: 1px;
          background: #ACACAC;
        }
      }

      .my-order-detail-invoice-summary-product-list-container {
        width: 100%;
        padding: 36px 0;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .my-order-detail-invoice-summary-product-list-item-container {
          height: 140px;
          display: flex;
          gap: 20px;

          .my-order-detail-invoice-summary-product-list-item-left-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .my-order-detail-invoice-summary-product-list-item-left-img-container {
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .my-order-detail-invoice-summary-product-list-item-left-qty-container {
              display: flex;
              align-items: center;
              gap: 10px;

              .my-order-detail-invoice-summary-product-list-item-left-qty-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 162.5% */
              }

              .my-order-detail-invoice-summary-product-list-item-left-qty-input {
                width: 75px;
                height: 25px;
                border: 1px solid #C8C8C8;
                display: flex;
                align-items: center;
                border-radius: 0;

                .ant-input-number-input-wrap {
                  height: 100%;

                  .ant-input-number-input {
                    height: 100%;
                  }
                }
              }
            }
          }

          .my-order-detail-invoice-summary-product-list-item-right-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .my-order-detail-invoice-summary-product-list-item-right-info-container {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .my-order-detail-invoice-summary-product-list-item-right-info-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .my-order-detail-invoice-summary-product-list-item-right-info-price {
                color: #393939;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; /* 110% */

                span {
                  font-size: 14px;
                  font-weight: 400;
                }
              }

              .my-order-detail-invoice-summary-product-list-item-right-info-size {
                color: #393939;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .my-order-detail-invoice-summary-product-list-item-right-btn {
              color: #a57b4f;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 185.714% */
              text-decoration-line: underline;
              cursor: pointer;
              user-select: none;
              width: max-content;
            }
          }
        }
      }

      .my-order-detail-invoice-summary-order-info-container {
        width: 100%;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .my-order-detail-invoice-summary-order-info-line {
          padding: 20px 0 8px;
        }

        .my-order-detail-invoice-summary-order-info-item-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .my-order-detail-invoice-summary-order-info-item-title {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }

          .my-order-detail-invoice-summary-order-info-item-val {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
  }

  .my-order-detail-bottom-container {
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .my-order-detail-btn {
      display: flex;
      width: 378px;
      height: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      border: 1px solid #ACACAC;

      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      cursor: pointer;
      user-select: none;
    }
  }
}
