.my-order-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .my-order-top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 10px 30px;
    
    > div {
      width: 308px;
    }
    
    .my-order-top-item-container2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .my-order-top-item-container {
        width: 48% !important;
      }
    }
    
    .my-order-top-item-container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      
      .my-order-top-item-title {
        color: #666;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      
      .my-order-top-item-select-container {
        width: 100%;

        .ant-select-selector {
          height: 42px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 12px;
          color: #262626;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;

          .ant-select-selection-search-input {
            height: 100%;
          }

          .ant-select-selection-placeholder {
            height: 100%;
            line-height: 42px;
          }

          .ant-select-selection-item {
            height: 100%;
            line-height: 42px;
          }
        }
      }
      
      .my-order-top-item-tree-select-container {
        flex: 1;
        
        .ant-select-selector {
          height: 42px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 12px 0 0 12px;
          color: #262626;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          padding-top: 0;
          
          .ant-select-selection-overflow {
            height: 100%;

            .ant-select-selection-overflow-item {
              height: 100%;

              .ant-select-selection-item {
                line-height: 38px;
                height: calc(100% - 3px);
              }
            }
          }

          .ant-select-selection-search-input {
            height: 100%;
          }

          .ant-select-selection-placeholder {
            height: 100%;
            line-height: 42px;
          }

          .ant-select-selection-item {
            height: 100%;
            line-height: 42px;
          }
        }
      }
      
      .my-order-top-item-btn {
        width: 42px;
        height: 42px;
        border-radius: 0 12px 12px 0;
      }
      
      .my-order-top-item-group {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .my-order-top-range-picker-container {
      height: 42px;
    }

    .my-order-balance-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding-right: 10px;

      .my-order-balance-label {
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
        user-select: none;
      }

      .my-order-balance-value {
        color: #1E4DF2;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  
  .my-order-list-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    .my-order-list-item-container {
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #C4C4C4;
      
      .my-order-list-item-content {
        padding: 10px 0;
        
        .my-order-list-item-row-1,
        .my-order-list-item-row-2 {
          color: #000;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          display: flex;
          align-items: center;
          height: 32px;
          margin-bottom: 5px;
        }

        .my-order-list-item-row-1 {
          font-weight: 700;
        }

        .my-order-list-item-row-2 {
          font-weight: 400;
        }

        .my-order-list-item-label {
          width: 250px;
        }
      }
      
      .my-order-list-item-btn {
        color: #1E4DF2;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  
  .my-order-show-more-btn-container {
    color: #a57b4f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    text-transform: uppercase;
    text-align: center;
    
    cursor: pointer;
    user-select: none;
  }
}

.my-order-top-item-select-popup-container {
  .ant-select-item-option,
  .ant-select-item  {
    height: 100%;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
  }
}
