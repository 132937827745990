.search_container {
    min-width: 170px;
    height: 45px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 24px;
    border-radius: 12px;
    border: 1px solid rgba(102, 102, 102, 0.35);
}
.search_input_v {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.search_input {
    min-width: 170px;
    width: 30%;
    display: flex;
}