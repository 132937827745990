.signup-login-container {
  width: 100%;
  padding: 50px 195px;
  margin-bottom: 100px;
  
  .signup-login-register-account-container {
    width: 595px;
    margin: 0 auto;

    .signup-login-register-account-divider {
      padding: 40px 0;
      display: flex;
      align-items: center;
      gap: 23px;

      &:before,
      &:after {
        content: ' ';
        flex: 1;
        height: 2px;
        background-color: rgba(102, 102, 102, 0.25);
      }
    }

    .signup-login-register-account-btn {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid #a57b4f;
      background: #FFF;

      color: #a57b4f;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
  }
}
