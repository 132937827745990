.wechat-popover-container {
  .wechat-popover--content-container {
    padding: 10px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    
    img {
      width: 280px;
    }
  }
}
