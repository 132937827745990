.privacy-policy-container {
  width: 100%;
  padding: 50px 60px;
  
  .privacy-policy-content-container {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .privacy-policy-content-list-item-container {
      display: flex;
      
      span {
        margin-right: 5px;
      }
    }
    
    .privacy-policy-content-list-2-container {
      padding-left: 26px;
      
      > div {
        display: flex;
        
        &:before {
          content: '·';
          font-weight: 900;
          width: 12px;
        }
      }
    }
  }
}
