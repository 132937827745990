.load-more {
  padding: 70px 0 90px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    position: static;
    display: inline-block;
    top: 50%;
    left: 50%;
    padding-left: 10px;
    vertical-align: middle;
  }

  .loading-bar {
    display: inline-block;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    animation: loading 1s ease-in-out infinite;
    margin-right: 5px;
  }

  .loading-bar:nth-child(1) {
    background-color: #ff6f37;
  }

  .loading-bar:nth-child(2) {
    background-color: #ff6f37;
    animation-delay: 0.09s;
  }

  .loading-bar:nth-child(3) {
    background-color: #ff6f37;
    animation-delay: .18s;
  }

  .loading-bar:nth-child(4) {
    background-color: #ff6f37;
    animation-delay: .27s;
  }

  @keyframes loading {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1, 2.2);
    }
    40% {
      transform: scale(1);
    }
  }
}