.layout-mobile-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .layout-header-container {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: #FFFFFF;
    min-height: 60px;
    width: 100vw;
    padding-bottom: 5px;
  }

  .app-content-container {
    width: 100%;
    padding-top: 60px;
  }
}
