.edit-order-products-container {
  width: 100%;
  padding: 0 100px;

  .edit-order-products-header-container {
    .content-header-title {
      padding: 0 157px;
    }
  }
  
  .edit-order-products-top-container {
    display: flex;
    align-items: center;
    gap: 30px;
    position: fixed;
    top: 50px;
    right: 60px;
    z-index: 999;
    
    .edit-order-products-btn-2,
    .edit-order-products-btn-1 {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      background: black;

      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
    
    .edit-order-products-btn-2 {
      background-color: transparent;
      color: #000000;
      border: 1px solid #000000;
    }
  }
}
