.signup-success-container {
  width: 740px !important;
  height: 865px;
  
  .ant-modal-content {
    padding: 80px 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 55px;
  }
}
