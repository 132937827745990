.edit-order-mobile-container {
  width: 100%;
  padding: 22px 16px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;

  .edit-order-content-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .edit-order-shopping-container {
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #C4C4C4;

      .edit-order-shopping-item-container {
        padding: 10px 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        height: 52px;

        .edit-order-shopping-item-label {
          width: 150px;
        }

        .edit-order-shopping-item-value {
          flex: 1;
          text-align: right;
        }
      }
    }

    .edit-order-payment-info-container {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #A1A1A1;

      .edit-order-payment-info-title {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;

        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .edit-order-payment-info-line {
        width: 100%;
        display: flex;

        &:after {
          content: ' ';
          width: 100%;
          height: 1px;
          background: #ACACAC;
        }
      }

      .edit-order-payment-info-item-container {
        padding: 10px 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        height: 52px;

        .edit-order-payment-info-item-label {
          width: 250px;
        }

        .edit-order-payment-info-item-value-container {
          display: flex;
          align-items: center;
          gap: 8px;

          .edit-order-payment-info-item-value-icon-container {
            width: 32px;
            height: 32px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }

    .edit-order-order-summary-container {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #A1A1A1;

      .edit-order-order-summary-title {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;

        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .edit-order-order-summary-line {
        width: 100%;
        display: flex;

        &:after {
          content: ' ';
          width: 100%;
          height: 1px;
          background: #ACACAC;
        }
      }

      .edit-order-order-summary-product-list-container {
        width: 100%;
        padding: 36px 0;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .edit-order-order-summary-product-list-item-container {
          height: 140px;
          display: flex;
          gap: 20px;

          .edit-order-order-summary-product-list-item-left-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .edit-order-order-summary-product-list-item-left-img-container {
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .edit-order-order-summary-product-list-item-left-qty-container {
              display: flex;
              align-items: center;
              gap: 10px;

              .edit-order-order-summary-product-list-item-left-qty-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 162.5% */
              }

              .edit-order-order-summary-product-list-item-left-qty-input {
                width: 75px;
                height: 25px;
                border: 1px solid #C8C8C8;
                display: flex;
                align-items: center;
                border-radius: 0;

                .ant-input-number-input-wrap {
                  height: 100%;

                  .ant-input-number-input {
                    height: 100%;
                  }
                }
              }
            }
          }

          .edit-order-order-summary-product-list-item-right-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .edit-order-order-summary-product-list-item-right-info-container {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .edit-order-order-summary-product-list-item-right-info-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .edit-order-order-summary-product-list-item-right-info-price {
                color: #393939;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; /* 110% */

                span {
                  font-size: 14px;
                  font-weight: 400;
                }
              }

              .edit-order-order-summary-product-list-item-right-info-size {
                color: #393939;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .edit-order-order-summary-product-list-item-right-btn {
              color: #a57b4f;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 185.714% */
              text-decoration-line: underline;
              cursor: pointer;
              user-select: none;
              width: max-content;
            }
          }
        }
      }

      .edit-order-order-summary-order-info-container {
        width: 100%;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .edit-order-order-summary-order-info-line {
          padding: 20px 0 8px;
        }

        .edit-order-order-summary-order-info-item-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .edit-order-order-summary-order-info-item-title {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }

          .edit-order-order-summary-order-info-item-val {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }

    .edit-order-invoice-summary-container {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #A1A1A1;

      .edit-order-invoice-summary-title {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;

        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
        margin-bottom: 8px;
      }

      .edit-order-invoice-summary-line {
        width: 100%;
        display: flex;

        &:after {
          content: ' ';
          width: 100%;
          height: 1px;
          background: #ACACAC;
        }
      }

      .edit-order-invoice-summary-product-list-container {
        width: 100%;
        padding: 36px 0;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .edit-order-invoice-summary-product-list-item-container {
          height: 140px;
          display: flex;
          gap: 20px;

          .edit-order-invoice-summary-product-list-item-left-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .edit-order-invoice-summary-product-list-item-left-img-container {
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .edit-order-invoice-summary-product-list-item-left-qty-container {
              display: flex;
              align-items: center;
              gap: 10px;

              .edit-order-invoice-summary-product-list-item-left-qty-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 162.5% */
              }

              .edit-order-invoice-summary-product-list-item-left-qty-input {
                width: 75px;
                height: 25px;
                border: 1px solid #C8C8C8;
                display: flex;
                align-items: center;
                border-radius: 0;

                .ant-input-number-input-wrap {
                  height: 100%;

                  .ant-input-number-input {
                    height: 100%;
                  }
                }
              }
            }
          }

          .edit-order-invoice-summary-product-list-item-right-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .edit-order-invoice-summary-product-list-item-right-info-container {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .edit-order-invoice-summary-product-list-item-right-info-title {
                color: #393939;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .edit-order-invoice-summary-product-list-item-right-info-price {
                color: #393939;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px; /* 110% */

                span {
                  font-size: 14px;
                  font-weight: 400;
                }
              }

              .edit-order-invoice-summary-product-list-item-right-info-size {
                color: #393939;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .edit-order-invoice-summary-product-list-item-right-btn {
              color: #a57b4f;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 185.714% */
              text-decoration-line: underline;
              cursor: pointer;
              user-select: none;
              width: max-content;
            }
          }
        }
      }

      .edit-order-invoice-summary-order-info-container {
        width: 100%;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .edit-order-invoice-summary-order-info-line {
          padding: 20px 0 8px;
        }

        .edit-order-invoice-summary-order-info-item-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .edit-order-invoice-summary-order-info-item-title {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }

          .edit-order-invoice-summary-order-info-item-val {
            color: #0A0D13;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
  }
}
