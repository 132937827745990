.cart-order-summary-container {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #A1A1A1;
  
  .cart-order-summary-top-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .cart-order-summary-top-btn,
    .cart-order-summary-title {
      display: flex;
      height: 48px;
      flex-direction: column;
      justify-content: center;

      color: #0A0D13;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
      margin-bottom: 8px;
    }
    
    .cart-order-summary-top-btn {
      color: black;
      cursor: pointer;
      user-select: none;
    }
  }

  .cart-order-summary-line {
    width: 100%;
    display: flex;

    &:after {
      content: ' ';
      width: 100%;
      height: 1px;
      background: #ACACAC;
    }
  }

  .cart-order-summary-product-list-container {
    padding: 36px 0;
    display: flex;
    flex-direction: column;
    gap: 36px;

    .cart-order-summary-product-list-item-container {
      height: 140px;
      display: flex;
      gap: 20px;

      .cart-order-summary-product-list-item-left-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .cart-order-summary-product-list-item-left-img-container {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .cart-order-summary-product-list-item-left-qty-container {
          display: flex;
          align-items: center;
          gap: 10px;

          .cart-order-summary-product-list-item-left-qty-title {
            color: #393939;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
          }

          .cart-order-summary-product-list-item-left-qty-input {
            width: 75px;
            height: 25px;
            border: 1px solid #C8C8C8;
            display: flex;
            align-items: center;
            border-radius: 0;

            .ant-input-number-input-wrap {
              height: 100%;

              .ant-input-number-input {
                height: 100%;
              }
            }
          }
        }
      }

      .cart-order-summary-product-list-item-right-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cart-order-summary-product-list-item-right-info-container {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .cart-order-summary-product-list-item-right-info-title {
            color: #393939;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: pre;
          }

          .cart-order-summary-product-list-item-right-info-price {
            color: #393939;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px; /* 110% */

            span {
              font-size: 14px;
              font-weight: 400;
            }
          }

          .cart-order-summary-product-list-item-right-info-size {
            color: #393939;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .cart-order-summary-product-list-item-right-btn {
          color: #a57b4f;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 185.714% */
          text-decoration-line: underline;
          cursor: pointer;
          user-select: none;
          width: max-content;
        }
      }
    }
  }

  .cart-order-summary-order-info-container {
    width: 100%;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 80px;

    .cart-order-summary-order-info-line {
      padding: 20px 0 8px;
    }

    .cart-order-summary-order-info-item-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cart-order-summary-order-info-item-title {
        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }

      .cart-order-summary-order-info-item-val {
        color: #0A0D13;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .cart-order-summary-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: black;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
