.forget-password-container {
  width: 600px !important;
  //height: 865px;

  .ant-modal-content {
    padding: 80px 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 55px;
  }
  
  .forget-password-title {
    color: black;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px; /* 170% */
    text-transform: uppercase;
    margin-bottom: 70px;
    
    span {
      font-weight: 500;
    }
  }
  
  .forget-password-tip {
    color: #c2bdbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }

  .forget-password-content-container {
    .forget-password-form-container {
      margin-bottom: 80px;
      width: 100%;

      .ant-form-item {
        margin-bottom: 40px;

        .ant-form-item-label {
          padding-bottom: 12px;

          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #666666;
          }

          .ant-form-item-required {
            &:after {
              content: '*';
              display: block;
              color: #EE1D52;
            }
          }
        }

        .ant-input {
          height: 56px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 12px;
          font-size: 18px;
          padding: 4px 11px;
        }
      }
    }
  }

  .forget-password-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: black;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }

  .forget-password-btn2 {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid #000000;
    background: #FFF;

    color: #000000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
  
  .forget-password-spacing {
    height: 30px;
  }
}
