.our-products-container {
  width: 100%;
  padding: 0 100px;

  .our-products-header-container {
    .content-header-title {
      padding: 0 157px;
    }
  }
}
