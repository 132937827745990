.my-profile-mobile-container {
  width: 100%;
  padding: 30px 16px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 80px;

  .my-profile-left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    gap: 10px;
    background: #F6F6F6;

    .my-profile-left-user-info-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .my-profile-left-user-info-upload-container {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        overflow: hidden;

        .ant-upload {
          width: 100% !important;
          height: 100% !important;
          margin-inline-end: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      .my-profile-left-user-info-avatar-container {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border-radius: 80px;
        border: 4px solid #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        font-size: 45px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .my-profile-left-user-info-username-container {
        color: #000;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 187.5% */
      }
    }

    .my-profile-left-menu-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .my-profile-left-menu-item-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-radius: 7px;
        background: rgba(141, 141, 141, 0.20);

        color: #393939;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 156.25% */

        cursor: pointer;
        user-select: none;
      }

      .my-profile-left-menu-item-active-container {
        background: black;
        color: #FFFFFF;
      }
    }
  }

  .my-profile-right-container {
    flex: 1;
    width: 100%;

    .my-profile-right-profile-information-container {
      padding-right: 0;
    }
  }
}
