.our-products-mobile-container {
  width: 100%;
  padding: 0 16px;

  .our-products-header-container {
    .content-header-title {
      padding: 0 16px;
    }
  }
}
