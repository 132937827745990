.footer-mobile-container {
  width: 100%;
  background-color: #FBFBFB;

  .footer-link {
    text-decoration-line: underline;
    cursor: pointer;
    user-select: none;
  }

  .footer-top-container {
    width: 100%;
    padding: 40px 16px;
    margin: 0 auto;

    .footer-top-content-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      flex-direction: column;

      .footer-top-content-title {
        color: black;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

      .footer-top-content-left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer-top-content-left-logo-container {
          width: 100%;
          height: 77px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .footer-top-content-left-des {
          width: 100%;
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 187.5% */
          margin-bottom: 20px;
        }

        .footer-top-content-left-get-in-touch-container {
          display: flex;
          align-items: center;

          .footer-top-content-left-get-in-touch-list {
            display: flex;
            align-items: center;
            padding: 5px 0;
            gap: 10px;
            margin-left: 25px;

            .footer-top-content-left-get-in-touch-item-container {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              user-select: none;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }

      .footer-top-content-right-container {
        width: 100%;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer-top-content-right-working-hours-container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-bottom: 20px;

          .footer-top-content-right-working-hours-item-container {
            color: #000;
            font-weight: 700;
            font-size: 14px;

            span {
              font-weight: 400;
            }
          }
        }

        .footer-top-content-right-contact-us-container {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .footer-top-content-right-contact-us-title {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 187.5% */
            padding-top: 15px;
          }

          .footer-top-content-right-contact-us-des {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
  }

  .footer-bottom-container {
    height: 18px;
    padding: 5px 0;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    font-weight: 400;
  }
}
