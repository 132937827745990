.user-info-form-mobile-container {
  margin-bottom: 20px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      padding-bottom: 6px;

      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 10px;
        color: #666666;
      }

      .ant-form-item-required {
        &:after {
          content: '*';
          display: block;
          color: #EE1D52;
        }
      }
    }

    .ant-input {
      height: 50px;
      border: 1px solid rgba(102, 102, 102, 0.35);
      border-radius: 6px;
      font-size: 14px;
      padding: 2px 8px;
    }

    .ant-select {
      .ant-select-selector {
        height: 50px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        border-radius: 6px;
        font-size: 14px;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-placeholder {
          line-height: 46px;
        }

        .ant-select-selection-item {
          line-height: 46px;
        }
      }
    }

    .ant-select-dropdown {
      .ant-select-item-option,
      .ant-select-item  {
        height: 100%;
        line-height: 21px;
        font-size: 12px;
      }
    }

    .ant-input-password {
      border: none;
      padding: 0;
      border-radius: 6px;

      .ant-input-suffix {
        margin-inline-start: 0;
      }
    }
  }

  .user-info-form-password-container {
    position: relative;

    .user-info-form-show-hide-password {
      position: absolute;
      top: 0;
      right: 0;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: rgba(102, 102, 102, 0.80);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
    }
    .user-info-form-show-hide-new-password {
      position: absolute;
      top: 0;
      right: 100px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
    }

    .user-info-form-show-hide-new-password2 {
      position: absolute;
      top: 0;
      right: 0px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: black;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
    }

    .user-info-form-forget-password {
      position: absolute;
      right: 0;
      top: 100px;

      color: black;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      user-select: none;
    }
  }
}
